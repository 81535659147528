import React, { Component } from 'react'
import axios from '../axios'
import { injectIntl, FormattedMessage, Link } from 'gatsby-plugin-intl'

class Monday extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: [],
      // option1: {
      //   ontime: '',
      //   offtime: '',
      //   fanspeed: '',
      // },
      // option2: {
      //   ontime: '',
      //   offtime: '',
      //   fanspeed: '',
      // },
      // option3: {
      //   ontime: '',
      //   offtime: '',
      //   fanspeed: '',
      // },
      opt1On: '',
      opt1Off: '',
      opt1Fan: '',
      opt2On: '',
      opt2Off: '',
      opt2Fan: '',
      opt3On: '',
      opt3Off: '',
      opt3Fan: '',
    }
    this.handleChange = this.handleChange.bind(this)
    console.log(this.state)
  }

  getMondaySchedule() {
    axios
      .get('/get/schedular_monday', {})
      .then(res => {
        const monday = res.data.days.monday
        this.setState({
          options: monday,
          // option1: {
          //   ontime: monday.option1.ontime,
          //   offtime: monday.option1.offtime,
          //   fanspeed: monday.option1.fanspeed,
          // },
          // option2: {
          //   ontime: monday.option2.ontime,
          //   offtime: monday.option2.offtime,
          //   fanspeed: monday.option2.fanspeed,
          // },
          // option3: {
          //   ontime: monday.option3.ontime,
          //   offtime: monday.option3.offtime,
          //   fanspeed: monday.option3.fanspeed,
          // },
          opt1On: monday.option1.ontime,
          opt1Off: monday.option1.offtime,
          opt1Fan: monday.option1.fanspeed,
          opt2On: monday.option2.ontime,
          opt2Off: monday.option2.offtime,
          opt2Fan: monday.option2.fanspeed,
          opt3On: monday.option3.ontime,
          opt3Off: monday.option3.offtime,
          opt3Fan: monday.option3.fanspeed,
        })
      })
      .catch(e => {
        console.error(e)
      })
  }

  componentDidMount() {
    this.getMondaySchedule()
  }

  handleSubmit(e) {
    e.preventDefault()
    axios
      .post('/post/monday_schedular', {
        headers: {
          'Content-Type': 'application/json',
        },
        days: {
          monday: {
            option1: {
              ontime: this.state.opt1On,
              offtime: this.state.opt1Off,
              fanspeed: this.state.opt1Fan,
            },
            option2: {
              ontime: this.state.opt2On,
              offtime: this.state.opt2Off,
              fanspeed: this.state.opt2Fan,
            },
            option3: {
              ontime: this.state.opt3On,
              offtime: this.state.opt3Off,
              fanspeed: this.state.opt3Fan,
            },
          },
        },
      })
      .then(res => {
        console.log(res)
      })
      .catch(e => {
        console.error(e)
      })
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
    console.log([e.target.name], e.target.value)
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className='interfaceRow justifyCenter'>
          <FormattedMessage id='pages.scheduler.monday' />
        </div>
        <div className='interfaceRow'>
          <div className='third inputfields'>
            <label htmlFor='opt1On'>On Time:</label>
            <input
              className='maxTen'
              type='time'
              name='opt1On'
              id='opt1On'
              value={this.state.opt1On}
              onChange={this.handleChange}
            />
          </div>
          <div className='third inputfields'>
            <label htmlFor='opt1Off'>Off Time:</label>
            <input
              className='maxTen'
              type='time'
              name='opt1Off'
              id='opt1Off'
              value={this.state.opt1Off}
              onChange={this.handleChange}
            />
          </div>
          <div className='third inputfields'>
            <label htmlFor='opt1Fan'>Fan Speed:</label>
            <input
              className='maxTwoFive'
              type='text'
              name='opt1Fan'
              id='opt1Fan'
              value={this.state.opt1Fan}
              onChange={this.handleChange}
            />{' '}
            &#37;
          </div>
        </div>

        <div className='interfaceRow'>
          <div className='third inputfields'>
            <label htmlFor='opt2On'>On Time:</label>
            <input
              className='maxTen'
              type='time'
              name='opt2On'
              id='opt2On'
              value={this.state.opt2On}
              onChange={this.handleChange}
            />
          </div>
          <div className='third inputfields'>
            <label htmlFor='opt2Off'>Off Time:</label>
            <input
              className='maxTen'
              type='time'
              name='opt2Off'
              id='opt2Off'
              value={this.state.opt2Off}
              onChange={this.handleChange}
            />
          </div>
          <div className='third inputfields'>
            <label htmlFor='opt2Fan'>Fan Speed:</label>
            <input
              className='maxTwoFive'
              type='text'
              name='opt2Fan'
              id='opt2Fan'
              value={this.state.opt2Fan}
              onChange={this.handleChange}
            />{' '}
            &#37;
          </div>
        </div>

        <div className='interfaceRow'>
          <div className='third inputfields'>
            <label htmlFor='opt3On'>On Time:</label>
            <input
              className='maxTen'
              type='time'
              name='opt3On'
              id='opt3On'
              value={this.state.opt3On}
              onChange={this.handleChange}
            />
          </div>
          <div className='third inputfields'>
            <label htmlFor='opt3Off'>Off Time:</label>
            <input
              className='maxTen'
              type='time'
              name='opt3Off'
              id='opt3Off'
              value={this.state.opt3Off}
              onChange={this.handleChange}
            />
          </div>
          <div className='third inputfields'>
            <label htmlFor='opt3Fan'>Fan Speed:</label>
            <input
              className='maxTwoFive'
              type='text'
              name='opt3Fan'
              id='opt3Fan'
              value={this.state.opt3Fan}
              onChange={this.handleChange}
            />{' '}
            &#37;
          </div>
        </div>

        <div className='interfaceRow'>
          <div className='half inputfields'>
            <Link to='/scheduler/' className='button'>
              Back
            </Link>
          </div>
          <div className='half inputfields'>
            <button className='buttonRight'>Accept Changes</button>
          </div>
        </div>
      </form>
    )
  }
}
export default injectIntl(Monday)
