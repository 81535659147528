import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import SEO from '../../components/seo'
import Monday from '../../components/operation/monday'

const MondayPage = ({ intl }) => (
  <>
    <SEO title={intl.formatMessage({ id: 'pages.scheduler.monday' })} />
    <>
      <div
        style={{
          maxWidth: `800px`,
          margin: `0 auto`,
        }}
      >
        <Monday />
      </div>
    </>
  </>
)

export default injectIntl(MondayPage)
